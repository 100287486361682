import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  PRODUCT_NORMALIZER,
  Product,
  WindowRef,
  OccRequestsOptimizerService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { BossZipCodeService } from '../../../shared/services/zip-code/boss-zip-code.service';

@Injectable()
export class BossOccProductAdapter {
  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private converter: ConverterService,
    private requestsOptimizer: OccRequestsOptimizerService,
    private winRef: WindowRef,
    private zipCodeService: BossZipCodeService,
  ) {}

  load(productCode: string, scope?: string): Observable<Product> {
    return this.http.get(this.bossGetEndpoint(productCode, scope)).pipe(this.converter.pipeable(PRODUCT_NORMALIZER));
  }

  protected bossGetEndpoint(code: string, scope?: string): string {
    let queryParams: Params | undefined;

    if (scope === 'zipCodeAndQty') {
      const zipCode = this.zipCodeService.get() || '';
      let quantity = this.winRef.isBrowser() ? sessionStorage.getItem('qty') : undefined;

      if (!quantity) {
        quantity = '1';
      }

      queryParams = {
        zipCode,
        quantity,
      };
    }

    return this.occEndpoints.getUrl(
      'product',
      {
        productCode: code,
      },
      queryParams,
      scope,
    );
  }
}
