import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ActiveCartService,
  CmsService,
  EventService,
  OrderEntry,
  PageMeta,
  PageMetaService,
  RoutingService,
  WindowRef,
} from '@spartacus/core';
import {
  BREAKPOINT,
  BreakpointService,
  KeyboardFocusService,
  NavigationEvent,
  StorefrontComponent,
} from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, tap } from 'rxjs/operators';
import { UserCentricsService } from 'src/app/spartacus/shared/services/usercentrics.service';
import { BossHamburgerMenuService } from '../hamburger-menu/boss-hamburger-menu.service';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';
import { BossDYContextType, BossDYEventType } from '../dynamic-yield/model';

@Component({
  selector: 'boss-storefront',
  templateUrl: './boss-storefront.component.html',
  styleUrls: ['./boss-storefront.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossStorefrontComponent extends StorefrontComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean> = this.breakpointService.isDown(BREAKPOINT.md);

  private subscription = new Subscription();

  readonly isCheckoutPage$ = this.cms
    .getCurrentPage()
    .pipe(
      map(
        (page) =>
          page &&
          (page.template === 'SpaMultiStepCheckoutSummaryPageTemplate' ||
            page.template === 'CheckoutLoginPageTemplate'),
      ),
    );

  constructor(
    private cms: CmsService,
    hamburgerMenuService: BossHamburgerMenuService,
    elementRef: ElementRef<HTMLElement>,
    keyboardFocusService: KeyboardFocusService,
    routing: RoutingService,
    private breakpointService: BreakpointService,
    protected winRef: WindowRef,
    protected uc: UserCentricsService, // Needed to add eventHandler on every Page load.
    protected activatedRoute: ActivatedRoute,
    private dynamicYieldService: BossDynamicYieldService,
    private eventService: EventService,
    private pageMetaService: PageMetaService,
    private activeCartService: ActiveCartService,
  ) {
    super(hamburgerMenuService, routing, elementRef, keyboardFocusService);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.isMobile$.pipe(filter((isMobile: boolean) => !isMobile)).subscribe(() => {
        this.collapseMenu();
      }),
    );

    // Dynamic Yield
    this.subscription.add(
      combineLatest([
        this.eventService.get(NavigationEvent).pipe(distinctUntilKeyChanged('url')),
        this.pageMetaService.getMeta().pipe(distinctUntilKeyChanged('canonicalUrl')),
        this.activeCartService
          .getEntries()
          .pipe(
            distinctUntilChanged(
              (a: OrderEntry[], b: OrderEntry[]) => JSON.stringify(a.length) === JSON.stringify(b.length),
            ),
          ),
      ])
        .pipe(
          tap(([, , order]) => {
            // Sync cart needs to be done on all pages, at all times, as it's a base for further calculations within DY
            this.dynamicYieldService.triggerEvent({
              name: 'Sync cart',
              properties: {
                dyType: BossDYEventType.SYNC_CART,
                value: order.reduce((acc: number, entry: OrderEntry) => {
                  return acc + entry.totalProductPrice.value;
                }, 0),
                cart: order.map((entry) => ({
                  productId: entry.product.code,
                  quantity: entry.quantity,
                  itemPrice: entry.product.price.value,
                })),
              },
            });
          }),
          map(([navigationEvent, meta, order]: [NavigationEvent, PageMeta, OrderEntry[]]) => {
            const dataCategory = meta.breadcrumbs.map((breadcrumb) => breadcrumb.label) || [];
            const dataProduct = [navigationEvent.params['productCode'] || ''];
            let dataCart = order.map((entry: OrderEntry) => entry.product.code);

            // Empty cart requires `['']` and not `[]`
            dataCart = dataCart?.length ? dataCart : [''];

            switch (navigationEvent.semanticRoute) {
              case 'home':
                this.dynamicYieldService.setContext(BossDYContextType.HOMEPAGE);
                break;
              case 'category':
                this.dynamicYieldService.setContext(BossDYContextType.PLP, dataCategory);
                break;
              case 'product':
                this.dynamicYieldService.setContext(BossDYContextType.PDP, dataProduct);
                break;
              case 'cart':
                this.dynamicYieldService.setContext(BossDYContextType.CART, dataCart);
                break;

              default:
                this.dynamicYieldService.setContext(BossDYContextType.OTHER);
                break;
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  collapseMenuIfClickOutside(event: MouseEvent): void {
    if (this.winRef.isBrowser()) {
      const element: HTMLElement = (event.target as HTMLElement).parentNode as HTMLElement;
      if (
        // default spa is checking for header, but their code is not working. So we use this
        element?.closest('header') &&
        // for some reason the back menu button is considered to bedirectly part of the header. filter it this way
        !element?.closest('boss-navigation') &&
        // ignore click on hamburger menu
        !element?.className.includes('hamburger') &&
        document.body.classList.contains('mobile-menu-expanded') // check if menu is expanded.
      ) {
        this.collapseMenu();
      }
    }
  }
}
