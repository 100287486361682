import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CmsService, Image, ProductService } from '@spartacus/core';
import { SwiperOptions } from 'swiper';
import {
  BossButtonClass,
  BossMainStageBannerModel,
  BossMainStageModel,
  BossMainStageType,
  BossStageStyleClass,
  BossStageTextLimitType,
} from './boss-main-stage.model';
import { getInterval } from './boss-main-stage';
import { ActivatedRoute } from '@angular/router';
import { makeStateKey } from '@angular/platform-browser';
import { AutoplayOptions } from 'swiper/types';
import { BossCarouselBaseComponent } from '../../shared/components/boss-carousel-base/boss-carousel-base.component';
import { BossTransferStateService } from '../../shared/services/boss-transfer-state';
import { BossHomepageBasicStageBanner } from '../../shared/models';

@Component({
  selector: 'boss-main-stage',
  templateUrl: './boss-main-stage.component.html',
  styleUrls: ['./boss-main-stage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossMainStageComponent extends BossCarouselBaseComponent implements OnInit, OnDestroy {
  BossStageTextLimitType = BossStageTextLimitType;

  items: BossMainStageBannerModel[];

  styleClass: BossStageStyleClass = BossStageStyleClass.DEFAULT;

  buttonClass: BossButtonClass = BossButtonClass.DEFAULT;

  swiperConfigBrowser: SwiperOptions = {
    autoplay: {
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      waitForTransition: false,
    },
    grabCursor: true,
    lazy: {
      checkInView: true,
      loadOnTransitionStart: true,
    },
    loop: true,
    navigation: false,
    pagination: {
      clickable: true,
    },
    preloadImages: false,
    preventInteractionOnTransition: true,
    resizeObserver: true,
    updateOnImagesReady: false,
  };

  readonly swiperConfigServer: SwiperOptions = {
    virtual: false,
    updateOnImagesReady: false,
  };

  private onDestroy$ = new Subject<void>();

  constructor(
    private componentData: CmsComponentData<BossMainStageModel>,
    private cmsService: CmsService,
    private productService: ProductService,
    private changeDetectorRef: ChangeDetectorRef,
    private bossTransferState: BossTransferStateService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: any, // eslint-disable-line
  ) {
    super(platformId);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadItems(): void {
    const currentUrlPath = this.activatedRoute.snapshot?.firstChild?.url?.[0]?.path || 'no_path';

    this.getCmsData(currentUrlPath)
      .pipe(
        tap((data: BossMainStageModel) => {
          this.setStylesByCmsData(data);
          this.swiperConfigBrowser = this.getAutoplayConfig(data.sliderTime);
          this.loadSwiperConfig();
        }),
        map((data: BossMainStageModel) => data?.bannerList?.trim()?.split(' ')),
        filter((codes: string[]) => !!codes?.length),
        map((codes: string[]) => codes.map((code: string) => this.getDataByCode(code))),
        map((banners: Observable<BossMainStageBannerModel>[]) =>
          banners.map((banner$: Observable<BossMainStageBannerModel>) => this.getMappedBanner$(banner$)),
        ),
        switchMap((banner$: Observable<BossMainStageBannerModel>[]) => combineLatest(...banner$)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((items: BossMainStageBannerModel[]) => {
        this.items = items;

        this.changeDetectorRef.detectChanges();
      });
  }

  private getDataByCode(code: string): Observable<BossMainStageBannerModel> {
    return this.cmsService.getComponentData<BossMainStageBannerModel>(code);
  }

  private getMappedBanner$(banner$: Observable<BossMainStageBannerModel>): Observable<BossMainStageBannerModel> {
    return banner$.pipe(
      map((banner: BossMainStageBannerModel) => {
        // eslint-disable-next-line
        return {
          ...banner,
          media: this.getMediaContainer(banner),
          product$: this.productService.get(banner.bannerPriceProduct),
        } as BossMainStageBannerModel;
      }),
    );
  }

  private getMediaContainer(banner: BossHomepageBasicStageBanner): Image {
    if (this.isSsr) {
      if (banner.mobileMedia) {
        return banner.mobileMedia as Image;
      }

      if (banner.media) {
        return banner.media as Image;
      }

      return { url: '' };
    }

    const isMobileImage = window.innerWidth < 992;

    return isMobileImage && banner?.mobileMedia ? (banner.mobileMedia as Image) : (banner.media as Image);
  }

  private getAutoplayConfig(delay: number): SwiperOptions {
    return {
      ...this.swiperConfigBrowser,
      ...(delay
        ? {
            autoplay: {
              ...(this.swiperConfigBrowser.autoplay as AutoplayOptions),
              delay: getInterval(delay),
            },
          }
        : {}),
    };
  }

  private setStylesByCmsData(data: BossMainStageModel): void {
    const theme = data?.colorTheme ?? data?.styleClasses;

    if (theme?.includes(BossMainStageType.BLACK)) {
      this.styleClass = BossStageStyleClass.BLACK;
      this.buttonClass = BossButtonClass.BLACK;
    }

    if (theme?.includes(BossMainStageType.GREEN)) {
      this.styleClass = BossStageStyleClass.GREEN;
      this.buttonClass = BossButtonClass.GREEN;
    }

    if (theme?.includes(BossMainStageType.SILK_GREY)) {
      this.styleClass = BossStageStyleClass.SILK_GREY;
    }

    if (theme?.includes(BossMainStageType.PASTEL_BLUE)) {
      this.styleClass = BossStageStyleClass.PASTEL_BLUE;
    }

    if (theme?.includes(BossMainStageType.PASTEL_VIOLET)) {
      this.styleClass = BossStageStyleClass.PASTEL_VIOLET;
    }

    if (theme?.includes(BossMainStageType.MAGENTA)) {
      this.styleClass = BossStageStyleClass.MAGENTA;
      this.buttonClass = BossButtonClass.MAGENTA;
    }
  }

  /**
   * Get CMS data. This method is using `TransferState` to help out with SSR rendering. `StateKey` is created on the fly, as we use main stage multiple times and we don't have any possibility to distinguish them.
   *
   * @param {string} url String which as a suffix for `StateKey` name
   * @return {*}  {Observable<BossMainStageModel>} CMS data or same data from `TransferState`
   */
  private getCmsData(url: string): Observable<BossMainStageModel> {
    const key = makeStateKey<BossMainStageModel>(`boss_main_stage_data_${url}`);
    const cmsData$ = this.bossTransferState.get<BossMainStageModel>(key);

    return (
      cmsData$ ||
      this.componentData.data$.pipe(
        tap((data: BossMainStageModel) => {
          this.bossTransferState.set<BossMainStageModel>(key, data);
        }),
      )
    );
  }
}
