import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { StoreFinderSearchPage } from '@spartacus/storefinder/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BossPointOfService } from '../../../../shared/models';

export interface FetchStoresModel {
  stores: BossPointOfService[];
}

@Injectable({ providedIn: 'root' })
export class BossStorefinderListService {
  private storesQuery$ = new ReplaySubject(1);

  constructor(private http: HttpClient, private occEndpoints: OccEndpointsService) {}

  nextQuery(query: string): void {
    this.storesQuery$.next(query);
  }

  getStores(): Observable<BossPointOfService[]> {
    return this.storesQuery$.asObservable().pipe(switchMap((query: string) => this.fetchStoresByQuery(query)));
  }

  fetchStoresByQuery(query): Observable<BossPointOfService[]> {
    const queryParam = query ? `&query=${query}` : '',
      url = this.occEndpoints.buildUrl(`stores?fields=FULL&pageSize=2000&sort=asc${queryParam}`);
    return this.http.get(url).pipe(map((data: FetchStoresModel) => data.stores ?? []));
  }

  findNearbyStoresByCood(latitude: number, longitude: number): Observable<StoreFinderSearchPage> {
    const url = this.occEndpoints.buildUrl(
      `stores?fields=FULL&pageSize=2000&sort=asc&latitude=${latitude}&longitude=${longitude}`,
    );
    return this.http.get(url);
  }
}
