import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HighlightService {
  private generateRegexSearch(text: string): RegExp {
    const searchWords = text
      .trim()
      .split(' ')
      .map((toReplace) =>
        toReplace
          .replace(/[^0-9A-Zäöüß]+/gi, '')
          .replace(/ae|a|ä/g, '(a|ä)')
          .replace(/oe|o|ö/g, '(o|ö)')
          .replace(/ue|u|ü/g, '(u|ü)')
          .replace(/ss|ß/, '(ss|ß)'),
      )
      .filter((f) => f);
    if (!searchWords.length) return null;
    try {
      return new RegExp(`(${searchWords.join(')|(')})`, 'gi');
    } catch (e) {
      console.warn(e);
    }
    return null;
  }

  highlight(text: string, search: string, tag = 'span', className = ''): string {
    const regExp = this.generateRegexSearch(search);
    if (!regExp) return text;
    return text.replace(regExp, (match) => `<${tag} class="${className} highlight">${match}</${tag}>`);
  }
}
