import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbMeta, CmsService, isNotNullable, Page, PageMetaService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { NavigationEnd, Router } from '@angular/router';
import { BossTransferStateService } from '../../shared/services/boss-transfer-state';
import { makeStateKey } from '@angular/platform-browser';

// TODO: Commented code below is working, but we have multiple server requests, so it'll be fixed in the future
@Component({
  selector: 'boss-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BossBreadcrumbComponent implements OnInit, OnDestroy {
  title$: Observable<string>;

  page$: Observable<Page>;

  crumbs$: Observable<BreadcrumbMeta[]>;

  titleAllowed = true;

  breadCrumbsAllowed = true;

  newTitle = false;

  bossIconConfig = bossIconConfig;

  private subscription = new Subscription();

  private titleDisabledRoutes =
    /^(\/newsletter|\/orderConfirmation|\/registration-info|\/favorites|\/storefinder|\/login|\/my-account)/;

  private breadcrumbDisabledRoutes = /^(\/orderConfirmation|\/checkout-login)/;

  private newTitleRoutes = /^(\/checkout-login)/;

  constructor(
    private pageMetaService: PageMetaService,
    private cmsService: CmsService,
    private cdRef: ChangeDetectorRef,
    private bossTransferState: BossTransferStateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.page$ = this.cmsService.getCurrentPage();

    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((event: NavigationEnd) => event.url),
          startWith(this.router.url),
        )
        .subscribe((url: string) => {
          this.titleAllowed = !this.titleDisabledRoutes.test(url);
          this.breadCrumbsAllowed = !this.breadcrumbDisabledRoutes.test(url);
          this.newTitle = this.newTitleRoutes.test(url);

          this.crumbs$ = this.getBreadcrumbs(url);
          this.title$ = this.getTitle(url);

          this.cdRef.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackByBreadcrumbLabel(index: number, item: BreadcrumbMeta): string {
    return item.label;
  }

  private getTitle(url: string): Observable<string> {
    const key = makeStateKey<string>(`boss_breadcrumbs_title_data_${url.replace(/\//g, '')}`);
    const title = this.bossTransferState.get<string>(key);

    return (
      title ||
      this.pageMetaService.getMeta().pipe(
        filter(isNotNullable),
        map(
          (meta) => {
            const heading = (meta.heading || meta.title) ?? '';

            if (heading.indexOf('[') === 0 && heading.indexOf(']') === heading.length - 1) {
              return '';
            }

            if (this.newTitle) {
              return heading.split(' | ')[0];
            }

            return heading;
          },
          tap((data: string) => {
            this.bossTransferState.set(key, data);
          }),
        ),
      )
    );
  }

  private getBreadcrumbs(url = 'no_url'): Observable<BreadcrumbMeta[]> {
    const key = makeStateKey<BreadcrumbMeta[]>(`boss_breadcrumbs_data_${url.replace(/\//g, '')}`);
    const breadcrumbs = this.bossTransferState.get<BreadcrumbMeta[]>(key);

    return (
      breadcrumbs ||
      this.pageMetaService.getMeta().pipe(
        map((meta) => meta?.breadcrumbs || []),
        tap((data: BreadcrumbMeta[]) => {
          this.bossTransferState.set(key, data);
        }),
      )
    );
  }
}
