import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BREAKPOINT, BreakpointService, NavigationEvent } from '@spartacus/storefront';
import { BossNavigationService } from './navigation.service';
import { BehaviorSubject, Observable, Subject, Subscription, fromEvent, interval, of } from 'rxjs';
import { NavCategoryLink, NavigationSubLevel } from './navigation.model';
import { EventService, Image, OccEndpointsService, WindowRef } from '@spartacus/core';
import { delayWhen, filter, map, take, takeUntil, throttle } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossHamburgerMenuService } from '../hamburger-menu/boss-hamburger-menu.service';

@Component({
  selector: 'boss-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossNavigationComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();
  navEntries$: Observable<NavigationSubLevel[]> = this.navigationService.getNavigationSubLevels();
  navEntries: NavigationSubLevel[];
  flyOutContent: BehaviorSubject<NavigationSubLevel[]> = new BehaviorSubject(undefined);
  flyOutContent$: Observable<NavigationSubLevel[]>;
  isMobile$: Observable<boolean> = this.breakpointService.isDown(BREAKPOINT.md);
  checkBanner: boolean;
  // TODO: Resolve eslint errors when refactor navigation copmonent
  secondLevelNavi: any; // eslint-disable-line
  activeNav: any; // eslint-disable-line
  currentNav: string;
  bossIconConfig = bossIconConfig;

  // TODO: Clear observables and Beh.Subj. mess below - we don't need so many checks
  // TODO: Remove async pipes and work on real data with subscription
  mobileNavEntries: BehaviorSubject<(NavigationSubLevel[] | NavCategoryLink[])[]>;
  mobileNavEntries$: Observable<(NavigationSubLevel[] | NavCategoryLink[])[]>;
  mobileSelectedCategory: string[] = new Array<string>();
  mobileSelectedCategoryUrl: string[] = new Array<string>();
  isExpanded: Observable<boolean> = this.hamburgerMenuService.isExpanded;
  hasSubNavContent = new BehaviorSubject<boolean>(false);
  hasSubNavContent$: Observable<boolean>;

  private mouseEnter$ = new Subject();
  private mouseLeave$ = new Subject();
  private isHovered = false;

  constructor(
    private navigationService: BossNavigationService,
    private breakpointService: BreakpointService,
    private occEndpointsService: OccEndpointsService,
    private router: Router,
    private hamburgerMenuService: BossHamburgerMenuService,
    private cdRef: ChangeDetectorRef,
    private events: EventService,
    private winRef: WindowRef,
  ) {}

  ngOnInit(): void {
    this.flyOutContent$ = this.flyOutContent.asObservable();
    this.hasSubNavContent$ = this.hasSubNavContent.asObservable();
    this.activeNav = this.router.url;

    this.subs.add(
      this.navEntries$.subscribe((navigationSubLevel) => {
        this.navEntries = navigationSubLevel;
        this.mobileNavEntries = new BehaviorSubject([navigationSubLevel]);
        this.mobileNavEntries$ = this.mobileNavEntries.asObservable();

        this.cdRef.detectChanges();
      }),
    );

    this.subs.add(
      this.isExpanded.pipe(filter((toggler) => !toggler)).subscribe(() => {
        if (this.mobileNavEntries) {
          this.mobileNavEntries.next([this.navEntries]);
          this.mobileSelectedCategory = [];
          this.mobileSelectedCategoryUrl = [];
        }
      }),
    );

    this.subs.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((event) => event as RouterEvent),
        )
        .subscribe((event) => {
          if (event.url) {
            this.flyOutContent.next(undefined);
            this.checkBanner = undefined;
          }
        }),
    );

    this.subs.add(
      this.events.get(NavigationEvent).subscribe((val) => {
        if (val.semanticRoute !== 'category') {
          this.activeNav = undefined;

          this.cdRef.detectChanges();
        }
      }),
    );

    if (this.winRef.isBrowser()) {
      this.subs.add(
        fromEvent(window, 'scroll')
          .pipe(throttle(() => interval(10)))
          .subscribe(() => {
            /**
             * Hide the desktop nav on scroll
             */
            this.flyOutContent.next(undefined);
            this.checkBanner = undefined;
          }),
      );
    }
  }

  navItemHovered(navEntry: NavigationSubLevel): void {
    this.subs.add(
      this.mouseEnter$
        .pipe(
          take(1),
          delayWhen(() => {
            return this.isHovered ? of(undefined) : interval(800);
          }),
          takeUntil(this.mouseLeave$),
        )
        .subscribe((navEntry) => {
          this.setSubNavItem(navEntry);
          this.isHovered = true;
        }),
    );

    this.subs.add(
      this.mouseLeave$.pipe(take(1)).subscribe(() => {
        this.hasSubNavContent.next(false);
        this.flyOutContent.next(undefined);

        this.checkBanner = undefined;
        this.currentNav = undefined;
        this.isHovered = false;
      }),
    );

    this.mouseEnter$.next(navEntry);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setMobileContent(selectedNavigationSublevel: NavigationSubLevel): void {
    this.mobileSelectedCategory[0] = 'Hauptmenü';
    this.mobileSelectedCategory.push(selectedNavigationSublevel.title);
    this.mobileSelectedCategoryUrl.push(selectedNavigationSublevel.url);

    if (selectedNavigationSublevel.children) {
      const nextNavEntryChildren = this.mobileNavEntries.getValue();
      nextNavEntryChildren.push(selectedNavigationSublevel.children);
      this.mobileNavEntries.next(nextNavEntryChildren);
    } else if (selectedNavigationSublevel.links) {
      const nextNavEntryLinks = this.mobileNavEntries.getValue();
      nextNavEntryLinks.push(selectedNavigationSublevel.links);
      this.mobileNavEntries.next(nextNavEntryLinks);
    }
  }

  mobileGoBack(): void {
    const temp = this.mobileNavEntries.getValue();
    temp.pop();
    this.mobileNavEntries.next(temp);
    this.mobileSelectedCategory.pop();
    this.mobileSelectedCategoryUrl.pop();
  }

  hasBanner(nav?: NavigationSubLevel): boolean {
    this.secondLevelNavi = nav;

    return nav.banner !== undefined;
  }

  getUrl(image: Image): string {
    return (
      this.occEndpointsService.getBaseUrl({
        baseUrl: true,
        prefix: false,
        baseSite: false,
      }) + image.url
    );
  }

  onSelectNav(nav?): void {
    if (nav) {
      this.activeNav = nav.url;
    }
  }

  private setSubNavItem(navEntry: NavigationSubLevel): void {
    this.currentNav = navEntry.url;
    this.flyOutContent.next(navEntry.children);
    this.hasSubNavContent.next(true);
    this.checkBanner = this.hasBanner(navEntry);
  }

  navPointerLeft(): void {
    this.mouseLeave$.next();
  }
}
