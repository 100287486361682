import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CmsSearchBoxComponent, OccEndpointsService, RoutingService, WindowRef } from '@spartacus/core';
import {
  BREAKPOINT,
  BreakpointService,
  CmsComponentData,
  SearchBoxComponent,
  SearchBoxComponentService,
} from '@spartacus/storefront';
import { Observable, Subject, Subscription, fromEvent, timer } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isIOS } from '../../shared/utils/utils';
import { HighlightService } from '../../shared/services/highlight.service';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossHamburgerMenuService } from '../hamburger-menu/boss-hamburger-menu.service';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';
import { BossDYEventType } from '../dynamic-yield/model';

@Component({
  selector: 'boss-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossSearchBoxComponent extends SearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;

  // TODO: fix lint error once refactor of search box component
  bossAutoCompleteResult$: any; // eslint-disable-line

  isOpen = false;

  searchInputValue = '';

  noResultsFound = false;

  lastSearchedResults = [];

  subscription = new Subscription();

  oldBreakpoint: string;

  bossIconConfig = bossIconConfig;

  popularSearchTerms$: Observable<string[]> = this.componentData.data$.pipe(
    map((data: CmsSearchBoxComponent) => data?.searchBoxItems?.split(/\s+/).map((item) => item?.toLowerCase())),
  );

  config = {
    minCharactersBeforeRequest: 3, // Default: 1
    displayProducts: true,
    displaySuggestions: false, // Default: true
    maxProducts: 10,
    maxSuggestions: 10,
    displayProductImages: true,
  };

  isMobile = false;

  private sessionStorage: Storage;

  private storageKey = 'boss_last_searched_results';

  private customSearch$ = new Subject();

  constructor(
    public searchBoxComponentService: SearchBoxComponentService,
    public componentData: CmsComponentData<CmsSearchBoxComponent>,
    public winRef: WindowRef,
    public routingService: RoutingService,
    public breakpointService: BreakpointService,
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private hamburgerMenuService: BossHamburgerMenuService,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private highlightService: HighlightService,
    private eRef: ElementRef,
    private ngZone: NgZone,
    private dynamicYieldService: BossDynamicYieldService,
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
    this.sessionStorage = winRef.sessionStorage;
  }

  ngOnInit(): void {
    if (this.winRef.isBrowser()) {
      this.ngZone.runOutsideAngular(() => {
        this.subscription.add(
          fromEvent(this.winRef.nativeWindow, 'scroll')
            .pipe(
              debounceTime(250),
              filter(() => isIOS() && this.isOpen),
              tap(() => {
                this.ngZone.run(() => {
                  this.searchInput.nativeElement.blur();
                  this.changeDetectionRef.detectChanges();
                });
              }),
            )
            .subscribe(),
        );
      });
    }

    this.ngZone.runOutsideAngular(() => {
      this.subscription.add(
        fromEvent(this.winRef.document, 'click')
          .pipe(
            filter(() => this.isMobile),
            tap((ev) => {
              if (!this.eRef.nativeElement.contains(ev.target)) {
                this.ngZone.run(() => {
                  this.close();
                  this.changeDetectionRef.detectChanges();
                });
              }
            }),
          )
          .subscribe(),
      );
    });

    this.subscription.add(
      this.ngZone.runOutsideAngular(() => {
        this.isExpanded
          .pipe(withLatestFrom(this.breakpointService.isDown(BREAKPOINT.md)))
          .subscribe(([isExpanded, isMobile]: [boolean, boolean]) => {
            if (isMobile && isExpanded) {
              this.ngZone.run(() => {
                this.closeMobileSearch();
              });
            }
          });
      }),
    );

    // close searchbar, when route changes
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          filter((event: NavigationEnd) => !!event.url),
          switchMap(() => timer(100)),
        )
        .subscribe(() => {
          if (this.searchInput) {
            this.searchInput.nativeElement.blur();
          }

          this.close();
          this.closeMobileSearch();
        }),
    );

    // when breakpoint changes the result should be resetet
    this.subscription.add(
      this.breakpointService.breakpoint$.subscribe((b: string) => {
        if (b !== this.oldBreakpoint) {
          this.close();
        }
        this.oldBreakpoint = b;
      }),
    );

    this.subscription.add(
      this.breakpointService.isDown(BREAKPOINT.md).subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectionRef.detectChanges();
      }),
    );

    this.subscription.add(
      this.customSearch$.pipe(debounceTime(250)).subscribe((searchData: { componentUid: string; query: string }) => {
        this.performCustomSearch(searchData.componentUid, searchData.query);
      }),
    );

    if (this.winRef.isBrowser()) {
      this.setLastSearchResults();
    }
  }

  setLastSearchResults(): void {
    const lastSearchResults = this.sessionStorage?.getItem(this.storageKey);

    if (lastSearchResults) {
      this.lastSearchedResults = JSON.parse(lastSearchResults);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openSearch(): void {
    this.isOpen = true;
    this.open();
    this.searchBoxComponentService.toggleBodyClass('overflow-hidden', true);
    this.searchBoxComponentService.toggleBodyClass('has-searchbox-results', true);
    if (this.winRef.isBrowser() && this.isMobile) {
      window.scrollTo(0, 0);
    }
  }

  handleResultsScroll(): void {
    if (this.searchInput && this.isMobile) {
      this.searchInput.nativeElement.blur();
    }
  }

  close(event = undefined): void {
    if (event === undefined || (event.type === 'blur' && event.relatedTarget !== null) || event.type !== 'blur') {
      this.searchBoxComponentService.toggleBodyClass('searchbox-is-active', false);
      this.searchBoxComponentService.toggleBodyClass('has-searchbox-results', false);
      this.searchBoxComponentService.toggleBodyClass('overflow-hidden', false);

      if (this.searchInput) {
        this.searchInput.nativeElement.value = '';
        this.bossAutoCompleteResult$ = undefined;
        this.searchInput.nativeElement.blur();
      }
    }
  }

  addElementToLastSearch(value: string): void {
    if (!this.lastSearchedResults.includes(value)) {
      if (this.lastSearchedResults.length === 3) {
        this.lastSearchedResults.shift();
      }

      this.lastSearchedResults.push(value);
      this.sessionStorage.setItem(this.storageKey, JSON.stringify(this.lastSearchedResults));
    }
  }

  customSearch(componentUid: string, query: string): void {
    this.customSearch$.next({ componentUid, query });
  }

  private performCustomSearch(componentUid: string, query: string): void {
    query = query.trim();

    if (query.length >= this.config.minCharactersBeforeRequest) {
      this.bossAutoCompleteResult$ = this.http.get(
        this.getSuggestionEndpoint(componentUid, query, this.config.maxSuggestions.toString()),
      );

      this.subscription.add(
        this.bossAutoCompleteResult$.subscribe((bossAutoCompleteResult) => {
          this.noResultsFound = true;

          if (
            bossAutoCompleteResult.products.length === 0 &&
            bossAutoCompleteResult.categories.length === 0 &&
            bossAutoCompleteResult.suggestions.suggestions.length === 0 &&
            bossAutoCompleteResult.market.length === 0 &&
            bossAutoCompleteResult.serviceAndCareer.length === 0
          ) {
            this.noResultsFound = false;
          }
        }),
      );
    } else {
      this.bossAutoCompleteResult$ = undefined;
      this.noResultsFound = true;
    }

    this.searchBoxComponentService.toggleBodyClass('has-searchbox-results', true);
    this.searchInputValue = query;
    this.openSearch();
  }

  protected getSuggestionEndpoint(ctUid: string, term: string, max: string): string {
    return this.occEndpoints.buildUrl('productSuggestions', {
      urlParams: { componentUid: ctUid },
      queryParams: { term, max },
    });
  }

  toggleMobileSearchOpen(): void {
    this.isOpen = !this.isOpen;
    this.changeDetectionRef.detectChanges();
    this.searchInput.nativeElement.focus();
  }

  closeMobileSearch(): void {
    this.isOpen = false;
    this.changeDetectionRef.detectChanges();
  }

  get isExpanded(): Observable<boolean> {
    return this.hamburgerMenuService.isExpanded;
  }

  buildLabel(originalName: string, className: string): string {
    return this.highlightService.highlight(originalName, this.searchInputValue, 'span', className);
  }

  launchSearchResult(event: UIEvent, query: string): void {
    if (!this.searchInputValue || this.searchInputValue.trim().length === 0) {
      return;
    }

    this.close();
    this.closeMobileSearch();
    this.bossAutoCompleteResult$ = undefined;
    this.searchBoxComponentService.launchSearchPage(query);

    this.dynamicYieldService.triggerEvent({
      name: 'Keyword search',
      properties: {
        dyType: BossDYEventType.KEYWORD_SEARCH,
        keywords: query,
      },
    });
  }

  clearSearch(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
      this.bossAutoCompleteResult$ = undefined;
    }
  }
}
